import {
  Product,
  ShopCard,
  ShopCardButton,
  ShopCardCornerBanner,
  ShopCardProduct,
} from "../sanity-schema";
import { getSlug, keyof } from "./utils";

export interface ShopCardWithSlug extends ShopCard {
  productSlug: string;
}

/**
 * DereferencedShopCardProduct type
 *
 * Representative of a dereferenced Sanity `ShopCardProduct`
 */
export type DereferencedShopCardProduct = Omit<ShopCardProduct, "banner"> & {
  banner: ShopCardCornerBanner;
};

/**
 * DereferencedShopCard type
 *
 * Representative of a dereferenced Sanity `ShopCard`
 */
export type DereferencedShopCard = Omit<
  ShopCardWithSlug,
  "product" | "button"
> & {
  product: DereferencedShopCardProduct;
  button: ShopCardButton;
};

/**
 * Sanity query string for fetching a fully dereferenced `ShopCard` on a `Product`
 *
 * @returns { DereferencedShopCard }
 */
// prettier-ignore
export const shopCardDereferencingQuery = `${keyof<Product>("shopCard")}{
  ...
  , "productSlug": ^.${getSlug<Product>("slug")}
  , "${keyof<DereferencedShopCard>("button")}": ${keyof<ShopCard>("button")}->{...}
  , "${keyof<DereferencedShopCard>("product")}": ${keyof<ShopCard>("product")}{
    ...
    , "${keyof<DereferencedShopCardProduct>("banner")}": ${keyof<ShopCardProduct>("banner")}->{...}
  }
}
`
