import { ConversionSchema } from "../transformers/conversion";
import { FlavorAndRoastCarousel, QuickShopCarousel } from "../sanity-schema";
import { productProjection } from "./product-queries";
import { filters, getLocalizedString, keyof } from "./utils";

export const quickShopCarouselCardQuery = (lang: string) => `
${filters.whereType("quickShopCarousel")} => {
  ...,
  ${keyof<QuickShopCarousel>("shopCards")}[]->${productProjection(lang)}
}`;

export interface FlavorAndRoastCarouselProjection {
  heading: string;
  cta: string;
  filters: {
    id: string;
    name: string;
    products: ConversionSchema[];
  }[];
}

export const flavorAndRoastCarousel = (lang: string) => `
_type=="flavorAndRoastCarousel" => {
  "heading": ${getLocalizedString<FlavorAndRoastCarousel>("heading", lang)},
  "cta": ${getLocalizedString<FlavorAndRoastCarousel>("cta", lang)},
  ${keyof<FlavorAndRoastCarousel>("filters")}[]{
    "id": _key,
    "name": ${getLocalizedString<FlavorAndRoastCarousel["filters"][0]>(
      "name",
      lang,
    )},
  ${keyof<FlavorAndRoastCarousel["filters"][0]>(
    "products",
  )}[]->${productProjection(lang)}
  }
}`;
