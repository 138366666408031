import { Product } from "../sanity-schema";
import {
  productFilters,
  productProjection,
  productProjectionSubs,
} from "./product-queries";
import { filters, keyof } from "./utils";

// prettier-ignore
export const subscribableProductFilter = `*[
    ${filters.whereType("product")}
    && ${productFilters}
    && count(${keyof<Product>("subscriptionOptions")}) > 0
  ]`;

// prettier-ignore
export const buildSubscribableProductsQuery = (lang: string) => `
  ${subscribableProductFilter}${productProjection(lang)}
`;

// prettier-ignore
export const buildSubscribableProductsQueryFiltered = (lang: string) => `
  ${subscribableProductFilter}${productProjectionSubs(lang)}
`;
