/**
 * Algolia-supported Segment Events
 *
 * https://segment.com/docs/connections/destinations/catalog/algolia-insights/
 *
 * We don't cover the `Order Completed` event here because it is sent by Chord's
 * OMS and thus cannot be modified client-side.
 */
export const AlgoliaSegmentEvent = {
  ProductViewed: "Product Viewed",
  ProductClicked: "Product Clicked",
  ProductAdded: "Product Added",
} as const;

export type AlgoliaSegmentEventKeys =
  (typeof AlgoliaSegmentEvent)[keyof typeof AlgoliaSegmentEvent];

type FilterPayload = {
  type: string;
  value: boolean;
};

type ProductPayload = {
  objectID: string;
};

/**
 * Assign `key`/`val` pair to `obj` if `val` is not null
 *
 * @param obj Object to which `key`/`val` pair should be assigned
 * @param key Desired key
 * @param val Desired value
 */
function assignIfExists(
  obj: object,
  key: string,
  val: string | number | ProductPayload[] | FilterPayload[],
) {
  Object.assign(obj, val ? { [key]: val } : null);
}

/** Invoke an array of Functions */
function assignProps(callbacks: (() => void)[]) {
  callbacks.forEach((callback) => callback());
}

/**
 * Segment middleware function for decorating event payloads with required
 * Algolia attributes
 *
 * Intercepts Segment events that are supported by Algolia. Parses URL search
 * params for specific keys and updates the payload with their values, as appropriate.
 *
 * References:
 *   - https://algolia.com/doc/guides/sending-events/planning/
 *   - https://segment.com/docs/connections/destinations/catalog/actions-algolia-insights/
 */
// prettier-ignore
export const assignAlgoliaProps = ({ payload, next }) => {
  const { context, event, properties: eventProps } = payload.obj;
  const urlParams = new URLSearchParams(context.page.search);

  const objectID = () => assignIfExists(eventProps, "product_id", urlParams.get("a_oID"));
  const index = () => assignIfExists(eventProps, "search_index", urlParams.get("a_idx"));
  const queryID = () => assignIfExists(eventProps, "query_id", urlParams.get("a_qID"));
  const position = () => assignIfExists(eventProps, "position", Number(urlParams.get("a_pos")) || null);

  switch (event) {
    case AlgoliaSegmentEvent.ProductClicked:
      assignProps([objectID, index, queryID, position]);
      break;
    case AlgoliaSegmentEvent.ProductViewed:
    case AlgoliaSegmentEvent.ProductAdded:
      assignProps([objectID, index, queryID]);
      break;
    default:
      break;
  }

  next(payload);
};
