import { LinkProps } from "@bluebottlecoffee/design-system/components";
import { Link as Schema } from "../sanity-schema";

type InternalUrlProps = {
  link: string;
  region: string;
  lang: string;
};

export function toInternalUrl({
  link,
  region,
  lang,
}: InternalUrlProps): string {
  const noLeadingSlash = link.charAt(0) !== "/" ? link : link.substring(1);
  return `/${region}/${lang}/${noLeadingSlash}`;
}

export function toLinkProps(
  data: Schema,
  region: string,
  lang: string,
): LinkProps {
  try {
    const url: string = data.external
      ? data.external
      : toInternalUrl({ link: data.internal!, region, lang });

    return {
      text: data.text[lang],
      url,
      isAnchorLink: data.isAnchorLink ?? false,
      newTab: data.blank ?? false,
    };
  } catch (e) {
    const name = data?.text?.[lang];
    throw Error(`${name ?? "Your document"} has an invalid link`);
  }
}
