import { ImageProps } from "@bluebottlecoffee/design-system/components";
import type {
  ImageResponsive as ImageResponsiveProps,
  ImageResponsiveWithCaption as ImageResponsiveWithCaptionProps,
} from "@bluebottlecoffee/design-system/components/lib/types";
import {
  ImageResponsive as ImageResponsiveSchema,
  ImageResponsiveWithCaption as ImageResponsiveWithCaptionSchema,
  ImageWithAltText as ImageWithAltTextSchema,
} from "../sanity-schema";

export type ImageType =
  | ImageWithAltTextSchema
  | ImageResponsiveSchema
  | ImageResponsiveWithCaptionSchema;

export function toImageProps(data: ImageType, lang: string): ImageProps {
  const image =
    data._type === "imageResponsive" ||
    data._type === "imageResponsiveWithCaption"
      ? data.desktop
      : data;

  const altText = image?.altText?.[lang];

  if (!image.source)
    throw new Error(
      altText
        ? `Image with alt text "${altText}" has not been selected. Either remove the alt text or select an image.`
        : "Image does not contain a source",
    );
  return {
    altText: altText ?? "",
    src:
      image.source?.format === "svg"
        ? image.source.url
        : `/${image.source.public_id}.${image.source.format}`,
  };
}

export function toImageResponsiveProps(
  data: ImageType,
  lang: string,
): ImageResponsiveProps {
  if (data._type === "imageWithAltText") {
    return {
      desktop: toImageProps(data, lang),
    };
  } else {
    return {
      desktop: toImageProps(data.desktop, lang),
      mobile: (data.mobile && toImageProps(data.mobile, lang)) || null,
    };
  }
}

export function toImageResponsiveWithCaptionProps(
  data: ImageResponsiveWithCaptionSchema,
  lang: string,
): ImageResponsiveWithCaptionProps {
  return {
    caption: data.caption[lang],
    desktop: toImageProps(data.desktop, lang),
    mobile: data.mobile && toImageProps(data.mobile, lang),
  };
}
