/**
 * Records any actions our users perform, along with any properties that describe the action.
 *
 * @param name        The name of the event.
 *
 * @param properties  A dictionary of properties for the event. If the event was 'Added to Cart',
 *                    it might have properties like price and productType.
 *                    See https://segment.com/docs/sources/website/analytics.js/#default-properties
 */
export const trackEvent = (name: string, properties?: Object) => {
  try {
    window.analytics?.track(name, properties);
  } catch (error) {
    /*
      See https://segment.com/docs/sources/server/http/#errors:
      "We currently return a 200 response for all API requests so debugging should be done in the
      Segment Debugger. The only exception is if the request is too large / json is invalid it will
      respond with a 400."
      */
    if (error?.response?.status === 400) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
};
