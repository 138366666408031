import Honeybadger from "@honeybadger-io/js";
import * as process from "process";
import { Region } from "../localization";

if (!process.env.NEXT_PUBLIC_REGION) {
  throw new Error("Missing NEXT_PUBLIC_REGION environment variable");
}

export const REGION = process.env.NEXT_PUBLIC_REGION! as Region;

export const parse = (
  template: string,
  data: { [key: string]: string | number } = {},
): string => {
  let result = template;

  Object.keys(data).forEach((key: string) => {
    const regex = new RegExp(`%{${key}}`, "g");

    if (!["string", "number"].includes(typeof data[key])) {
      Honeybadger.notify("Missing data for translation interpolation", {
        template,
        key,
        data,
      });
    } else {
      result = result.replace(regex, data[key].toString());
    }
  });

  return result;
};
