"use client";

// https://nextjs.org/docs/app/api-reference/next-config-js/images#cloudinary
export default function cloudinaryLoader({ src, width, quality }) {
  // guard against file names with spaces
  const source = encodeURI(src);
  const params = ["f_auto", "c_limit", `w_${width}`, `q_${quality || "auto"}`];
  return `https://res.cloudinary.com/hbhhv9rz9/image/upload/${params.join(
    ",",
  )}${source}`;
}
