import { createClient } from "@sanity/client";
import { format } from "date-fns";
import { isFeatureEnabled } from "./utils/is-feature-enabled";

export default () =>
  createClient({
    projectId: process.env.SANITY_STUDIO_API_PROJECT_ID,
    dataset: process.env.NEXT_PUBLIC_SANITY_STUDIO_API_DATASET,
    token: process.env.SANITY_STUDIO_TOKEN,
    useCdn: isFeatureEnabled(process.env.NEXT_PUBLIC_ENABLE_SANITY_CDN),
    apiVersion:
      process.env.NEXT_PUBLIC_SANITY_API_VERSION ||
      format(new Date(), "yyyy-MM-dd"),
  });
