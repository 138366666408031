import cookie from "js-cookie";
import { trackEvent } from "../segment/track-event";

/**
 * Determine if a cookie with a given name exists.
 *
 * @export
 * @return {*}  {boolean}
 */
export function cookieExists(name: string): boolean {
  return cookie.get(name) !== undefined;
}

/**
 * Set a cookie to store that the user dismissed the opt in
 * so they are not prompted again.
 *
 * @export
 */
export function handleEmailOptInDismiss() {
  cookie.set("newsletter-dismissed", "true");
}

/**
 * Opt a user into a newsletter via their e-mail address by
 * tracking an event into Segment. This also sets a cookie
 * to store that the user has opted in.
 *
 * @export
 * @param {string} emailAddress
 */
export function handleEmailOptIn(emailAddress: string) {
  trackEvent("Newsletter Opt In", { email: emailAddress });
  cookie.set("newsletter-opted-in", "true");
}

/**
 * Determine if the user already opted in by checking a cookie.
 *
 * @export
 * @return {*}  {boolean}
 */
export function userAlreadyOptedInToNewsletter(): boolean {
  return cookieExists("newsletter-opted-in");
}

/**
 * Determine if the user dismissed the newsletter opt in by
 * checking for a cookie.
 *
 * @export
 * @return {*}  {boolean}
 */
export function userDismissedNewsletter(): boolean {
  return cookieExists("newsletter-dismissed");
}

/**
 * Determine whether or not an e-mail opt in prompt should be
 * displayed.
 *
 * @export
 * @param {boolean} isLoggedIn A true/false value indicating
 *                             whether the user is logged in or not
 * @return {*}  {boolean}
 */
export function shouldDisplayEmailOptIn(isLoggedIn: boolean): boolean {
  return (
    !isLoggedIn &&
    !userAlreadyOptedInToNewsletter() &&
    !userDismissedNewsletter()
  );
}
