import { BreadcrumbsCookies } from "@bluebottlecoffee/design-system/components";
import { useRouter } from "next/router";
import { useEffect } from "react";
import Cookies from "js-cookie";

interface SetBreadcrumbsCookies {
  pageProps: any;
  numberOfCrumbs?: 1 | 2 | 3 | 4;
}

export const setBreadcrumbsCookies = ({
  pageProps,
  numberOfCrumbs = 4,
}: SetBreadcrumbsCookies) => {
  const { asPath } = useRouter();

  useEffect(() => {
    const breadcrumbsCookies = Cookies.get("breadcrumbs");

    const breadcrumbs: BreadcrumbsCookies[] =
      typeof breadcrumbsCookies !== "undefined"
        ? JSON.parse(breadcrumbsCookies)
        : [];

    const pageMatch = asPath.match(
      "collection|shop|subscriptions|product",
    )?.[0];

    const getPageType = () => {
      switch (pageMatch) {
        case "collection":
          return "shopCollection";
        case "subscriptions":
          return "subscriptionPage";
        case "product":
          return "conversion";
        default:
          return pageMatch;
      }
    };

    const pageType = getPageType();

    const title =
      pageProps[pageType]?.name[pageProps.lang] || pageProps[pageType]?.name;

    const breadcrumb: BreadcrumbsCookies = {
      title: (title ?? "").toLowerCase(),
      asPath,
    };

    const breadcrumbsLenght = breadcrumbs.length - 1;
    const isNotEqualCrumb =
      breadcrumbs[breadcrumbsLenght]?.title !== breadcrumb.title;

    if (breadcrumb.title) {
      const newBreadcrumbs = isNotEqualCrumb
        ? [...breadcrumbs.splice(-(numberOfCrumbs - 1)), breadcrumb]
        : breadcrumbs;

      if (pageType === "conversion" && newBreadcrumbs.length <= 1) {
        return;
      }

      Cookies.set("breadcrumbs", JSON.stringify(newBreadcrumbs));
    } else if (!asPath.includes("product")) {
      Cookies.set("breadcrumbs", "[]");
    }
  }, [asPath]);
};
