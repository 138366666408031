import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import {
  RedemptionModal,
  RedemptionModalProps,
} from "@bluebottlecoffee/design-system/components";
import { useRouter } from "next/router";
import { useUser, useAuth, useAnalytics } from "@chordcommerce/react-autonomy";
import { valueOrFirst } from "../lib/utils";
import { accountCurrentSubscriptionsPage } from "../lib/link-builders";
import { BaseProps } from "../lib/sanity/shared";
import {
  RedemptionTransformerProps,
  toGiftSubRedemptionProps,
} from "../lib/transformers/redemptionModal";

export type ConditionalGiftSubRedemptionProps = PropsWithChildren<{
  pageProps: BaseProps;
}>;

export const ConditionalGiftSubRedemptionModal = ({
  pageProps,
}: ConditionalGiftSubRedemptionProps) => {
  const { lang, region, giftSubRedemption } = pageProps;
  const { isLoggedIn, login } = useAuth();
  const { trackEmailCaptured } = useAnalytics();

  const [isOpen, setIsOpen] = useState(false);
  const { query } = useRouter();
  const router = useRouter();
  const { loadUser } = useUser();

  //  Combine all query string parameters (both historical, and queryString), map the
  //  values to a new array where each value is the result of the query search in the url.
  const queryStringArrayValues = useMemo(
    () => [
      valueOrFirst(query?.[giftSubRedemption?.queryString?.[lang]] ?? ""),
      ...(giftSubRedemption?.historicalQueryStringArray?.map((i) =>
        valueOrFirst(query?.[i?.[lang]] ?? ""),
      ) ?? []),
    ],
    [giftSubRedemption, query, lang],
  );

  // Find the first result where string is not empty.
  const redemptionEmail = queryStringArrayValues.find((i) => i !== "");

  useEffect(() => {
    // Display Modal if query param for email exists. (redemption-email)
    setIsOpen(!!redemptionEmail);
  }, [redemptionEmail]);

  const navigateToAccountCurrentSubs = async () => {
    // Load user into state before pushing new page
    await loadUser();
    await router.push(accountCurrentSubscriptionsPage({ lang, region }));
  };

  const handleLogin = async (email: string) => {
    if (!email) return;
    trackEmailCaptured({
      emailCapture: {
        email,
      },
    });

    await login({
      email,
      showUI: true,
      redirectURI: `${window.location.href}`,
    });

    await navigateToAccountCurrentSubs();
  };

  const props: RedemptionTransformerProps | null =
    giftSubRedemption && redemptionEmail
      ? toGiftSubRedemptionProps(giftSubRedemption, lang, redemptionEmail)
      : null;

  const loginGiftSubProps: RedemptionModalProps | null = props
    ? {
        ...props,
        handleLogin,
        navigateTo: navigateToAccountCurrentSubs,
        isLoggedIn,
        isOpen,
      }
    : null;

  return loginGiftSubProps ? <RedemptionModal {...loginGiftSubProps} /> : <></>;
};
