import { BeforeNotifyHandler } from "@honeybadger-io/core/build/src/types";

const skippableErrors = {
  ApiError: [
    "UnhandledPromiseRejectionWarning: ApiError: You are not authorized to perform that action.",
  ],
  AuthError: [
    "UnhandledPromiseRejectionWarning: AuthError: Missing and/or invalid callback query params in URL.",
    "UnhandledPromiseRejectionWarning: AuthError: Cannot return the session token.",
  ],
};

// eslint-disable-next-line consistent-return
const handler: BeforeNotifyHandler = (notice) => {
  if (skippableErrors[notice.name]?.includes(notice.message)) {
    return false;
  }
};

export default handler;
