import { OptionValue, Variant } from "../sanity-schema";
import { keyof } from "./utils";

export type SourceOptionValue = Pick<OptionValue, "presentation">;

export type DereferencedVariant = Required<
  Omit<Variant, "optionValues"> & {
    optionValues: SourceOptionValue[];
  }
>;

export const variantsProjection = `{
  ...,
  ${keyof<Variant>("optionValues")}[]->{
    ${keyof<OptionValue>("presentation")}
  }
}`;

// prettier-ignore
export const forSaleVariantFilter: string = `[${keyof<Variant>("forSale")}==true]`;
