import { RedemptionModalProps } from "@bluebottlecoffee/design-system/components";
import { GiftCardRedemption, GiftSubRedemption } from "../sanity-schema";
import { toImageProps } from "./image";

export type RedemptionTransformerProps = Pick<
  RedemptionModalProps,
  "claimModalContentProps" | "loginModalContentProps"
>;

export function toGiftCardRedemptionProps(
  data: GiftCardRedemption,
  lang: string,
  email: string,
): RedemptionTransformerProps {
  return {
    claimModalContentProps: {
      icon:
        data.claimGiftCardContent?.icon &&
        toImageProps(data.claimGiftCardContent?.icon, lang),
      copy: {
        content: data.claimGiftCardContent.copy.content[lang],
        submitButtonText: data.claimGiftCardContent.copy.submitButtonText[lang],
        title: data.claimGiftCardContent.copy.title[lang],
      },
    },
    loginModalContentProps: {
      copy: {
        confirmEmailConfirmation:
          data.loginGiftCardContent.copy.confirmEmailConfirmation[lang],
        confirmModalInstructions:
          data.loginGiftCardContent.copy.confirmModalInstructions[lang],
        title: data.loginGiftCardContent.copy.title[lang],
      },
      email,
      icon:
        data.loginGiftCardContent?.icon &&
        toImageProps(data.loginGiftCardContent.icon, lang),
    },
  };
}

export function toGiftSubRedemptionProps(
  data: GiftSubRedemption,
  lang: string,
  email: string,
): RedemptionTransformerProps {
  return {
    claimModalContentProps: {
      icon:
        data.claimGiftSubContent?.icon &&
        toImageProps(data.claimGiftSubContent.icon, lang),
      copy: {
        content: data.claimGiftSubContent.copy.content[lang],
        submitButtonText: data.claimGiftSubContent.copy.submitButtonText[lang],
        title: data.claimGiftSubContent.copy.title[lang],
      },
    },
    loginModalContentProps: {
      copy: {
        confirmEmailConfirmation:
          data.loginGiftSubContent.copy.confirmEmailConfirmation[lang],
        confirmModalInstructions:
          data.loginGiftSubContent.copy.confirmModalInstructions[lang],
        title: data.loginGiftSubContent.copy.title[lang],
      },
      email,
      icon:
        data.loginGiftSubContent?.icon &&
        toImageProps(data.loginGiftSubContent.icon, lang),
    },
  };
}
