import { CognitoAmplifyClient } from "@chordcommerce/chord-cognito-amplify";

const getClient = () => {
  if (
    !process.env.NEXT_PUBLIC_COGNITO_IDENTITY_SERVICE_URL ||
    !process.env.NEXT_PUBLIC_COGNITO_IDENTITY_POOL_ID ||
    !process.env.NEXT_PUBLIC_COGNITO_REGION ||
    !process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID ||
    !process.env.NEXT_PUBLIC_COGNITO_USER_POOL_WEB_CLIENT_ID
  ) {
    throw new Error("Cognito environment variables must be set!");
  }

  return new CognitoAmplifyClient({
    // Make this where ever you want to redirect to
    redirectUri: "/auth/callback",
    identityServiceUrl: process.env.NEXT_PUBLIC_COGNITO_IDENTITY_SERVICE_URL,
    Auth: {
      identityPoolId: process.env.NEXT_PUBLIC_COGNITO_IDENTITY_POOL_ID,
      region: process.env.NEXT_PUBLIC_COGNITO_REGION,
      userPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
      userPoolWebClientId:
        process.env.NEXT_PUBLIC_COGNITO_USER_POOL_WEB_CLIENT_ID,
    },
  });
};

const client = getClient();
export default client;
