/**
 * Convert cookie into usable object
 */
export function parseCookie(): { [key: string]: any } {
  return document.cookie.split("; ").reduce((prev, current) => {
    const [name, ...value] = current.split("=");
    Object.assign(prev, { [name]: value.join("=") });
    return prev;
  }, {});
}
